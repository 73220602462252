import { Button } from "@progress/kendo-react-buttons";
import {
	boxSizingIcon,
	chevronDownIcon,
	chevronUpIcon,
	dollarIcon,
	eyeIcon,
	fileConfigIcon,
	fileIcon,
	pencilIcon,
	textboxIcon,
} from "@progress/kendo-svg-icons";
import { Link } from "react-router-dom";
import { DetailsDisplayLocation } from "./DetailsDisplayLocation";
import { DetailsInformationCard } from "./DetailsInformationCard";
import { DGrid } from "./GenericGrid";
import { JobDetailsCard } from "./JobDetailsCard";
import type { JobDetailsResponse } from "./api/JobApi";
import { toCurrency } from "./helpers";

type JobDetailsInformationProps = {
	data: JobDetailsResponse;
	onEditClick: () => void;
	onViewDocumentsClick: () => void;
};
export const JobDetailsInformation = ({
	data,
	onEditClick,
	onViewDocumentsClick,
}: JobDetailsInformationProps) => {
	const totalCostOfLegs = data.legs.reduce((acc, x) => acc + (x.cost ?? 0), 0);
	return (
		<JobDetailsCard
			title="Job Information"
			buttons={
				<>
					<Button svgIcon={eyeIcon} onClick={onEditClick}>
						Documents{" "}
						{data.documents.length ? `(${data.documents.length})` : undefined}
					</Button>
					<Button
						svgIcon={pencilIcon}
						themeColor="primary"
						onClick={onViewDocumentsClick}
					>
						Edit Info
					</Button>
				</>
			}
		>
			<DGrid columns="repeat(2, 1fr)" gap="8px">
				<DetailsInformationCard
					icon={dollarIcon}
					title="Customer"
					value={data.customerName}
				/>
				<DetailsInformationCard
					icon={fileConfigIcon}
					title="Job Type"
					value={data.typeName}
				/>
				<DetailsInformationCard
					icon={dollarIcon}
					title="Price"
					value={data.price ? toCurrency(data.price, data.currencyCode) : "-"}
				/>
				<DetailsInformationCard
					icon={eyeIcon}
					title="Operator"
					value={data.assignedTo}
				/>
				<DetailsInformationCard
					icon={fileIcon}
					title="Purchase Order"
					value={data.purchaseOrderNumber}
				/>
				<DetailsInformationCard
					icon={dollarIcon}
					title="Total Cost of Legs"
					value={
						totalCostOfLegs
							? toCurrency(totalCostOfLegs, data.currencyCode)
							: "-"
					}
				/>
				<DetailsInformationCard
					icon={dollarIcon}
					title="VAT"
					value={data.useVat ? "Yes" : "No"}
				/>
				<DetailsInformationCard
					icon={dollarIcon}
					title="VAT Rate"
					value={data.vatRate ? `${data.vatRate}%` : "-"}
				/>
				<DetailsInformationCard
					icon={dollarIcon}
					title="Profit"
					value={data.profitPercent ? `${data.profitPercent}%` : "-"}
				/>
				<DetailsInformationCard
					icon={boxSizingIcon}
					title="Loads"
					value={data.loads.map((load) => (
						<Link to={`/loads/${load.id}`} key={load.id}>
							{load.uniqueId}
						</Link>
					))}
				/>
				<DetailsInformationCard
					icon={chevronUpIcon}
					title="Start Location"
					value={<DetailsDisplayLocation {...data.startLocation} />}
				/>
				<DetailsInformationCard
					icon={chevronDownIcon}
					title="End Location"
					value={<DetailsDisplayLocation {...data.endLocation} />}
				/>
				<DetailsInformationCard
					icon={textboxIcon}
					title="Notes"
					value={data.notes ?? "-"}
				/>
			</DGrid>
		</JobDetailsCard>
	);
};
