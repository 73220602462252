import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTitle } from "react-use";
import { DGrid } from "./GenericGrid";
import { JobDetailsGoods } from "./JobDetailsGoods";
import { JobDetailsInformation } from "./JobDetailsInformation";
import { JobDetailsLegs } from "./JobDetailsLegs";
import { JobDetailsTitle } from "./JobDetailsTitle";
import { jobApi } from "./helpers";
import { useLegFormDialog } from "./useLegFormDialog";

export const JobDetailsPage2 = () => {
	const params = useParams();
	const [openLegFormDialog, legFormDialog] = useLegFormDialog();
	const jobId = Number(params.id);
	const response = useQuery({
		queryKey: ["job-details", jobId],
		queryFn: () => jobApi.bff.bffJobDetailsDetail(jobId),
	});
	const data = useMemo(() => response.data?.data, [response.data?.data]);
	const navigate = useNavigate();
	useTitle(
		data ? `Job ${data.id} - ${data.uniqueId}` : "Job details, loading...",
	);
	if (!data) return <div>Loading...</div>;
	return (
		<DGrid rows="50px 1fr">
			{legFormDialog}
			<JobDetailsTitle
				data={data}
				onGoBackClick={() => navigate("/jobs2")}
				onActionClick={(x) => console.log(`Action clicked: ${x}`)}
			/>
			<DGrid columns="1fr 1.5fr">
				<DGrid rows="1fr 1fr">
					<JobDetailsInformation
						data={data}
						onEditClick={() => console.log("Edit clicked")}
						onViewDocumentsClick={() => console.log("View clicked")}
					/>
					<JobDetailsGoods
						data={data}
						onSplitGoodsClicked={() => console.log("Split clicked")}
						onEditGoodClicked={(id) => console.log(`Edit good clicked: ${id}`)}
						onRemoveGoodClicked={(id) =>
							console.log(`Remove good clicked: ${id}`)
						}
						onGenerateCMRClicked={(id) =>
							console.log(`Generate CMR clicked: ${id}`)
						}
						onGenerateCargoLabelClicked={(id) =>
							console.log(`Generate cargo label clicked: ${id}`)
						}
					/>
				</DGrid>
				<JobDetailsLegs
					data={data}
					onAddLegClicked={() => openLegFormDialog({ jobId })}
					onEditLegClicked={(id) => openLegFormDialog({ id })}
					onRemoveLegClicked={(id) => console.log(`Remove leg clicked: ${id}`)}
					onViewDocumentsClicked={(id) =>
						console.log(`View documents clicked: ${id}`)
					}
					onAttachPODClicked={(id) => console.log(`Attach POD clicked: ${id}`)}
					onCollectionNoteClicked={(id) =>
						console.log(`Collection note clicked: ${id}`)
					}
					onDeliveryTicketClicked={(id) =>
						console.log(`Delivery ticket clicked: ${id}`)
					}
					onSubcontractorOrderClicked={(id) =>
						console.log(`Subcontractor order clicked: ${id}`)
					}
					onLegNewStatusClicked={(id, newStatus) =>
						console.log(`New status clicked: ${id} - ${newStatus}`)
					}
				/>
			</DGrid>
		</DGrid>
	);
};
