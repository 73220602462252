import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, number, object, string } from "yup";
import { DFlex } from "./GenericFlex";
import { GenericForm, IText } from "./GenericForm";
import { GenericPage } from "./GenericPage";
import { type TypedGridColumnProps, jobApi, toasted } from "./helpers";

const TrailerTypeSchema = object({
	id: number().label("ID"),
	name: string().required().label("Name"),
});
type TrailerTypeFormObject = InferType<typeof TrailerTypeSchema>;

type TrailerTypeFormProps = {
	defaultValues?: Partial<TrailerTypeFormObject>;
	onSubmit: (data: TrailerTypeFormObject) => void;
};
const TrailerTypeForm = ({ defaultValues, onSubmit }: TrailerTypeFormProps) => {
	const form = useForm<TrailerTypeFormObject>({
		resolver: yupResolver(TrailerTypeSchema),
		defaultValues,
	});
	return (
		<GenericForm
			form={form}
			schema={TrailerTypeSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<IText n="name" />
				</div>
			</DFlex>
		</GenericForm>
	);
};
const TrailerTypeFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<TrailerTypeFormProps, "onSubmit" | "defaultValues">) => (
	<TrailerTypeForm
		defaultValues={defaultValues}
		onSubmit={async (data) => {
			const { id, ...rest } = data;
			const processData = async () => {
				if (id) await jobApi.trailerType.trailerTypeUpdate({ id, ...rest });
				else await jobApi.trailerType.trailerTypeCreate({ ...rest });
				onSubmit(data);
			};
			toasted(
				processData(),
				id ? "Updating Trailer Type" : "Creating Trailer Type",
			);
		}}
	/>
);

type TrailerType = TrailerTypeFormObject & { id: number };
const defaultColumns: TypedGridColumnProps<TrailerType>[] = [
	{ field: "name", title: "Name" },
];
const useFetchData = (): ComponentProps<
	typeof GenericPage<TrailerType>
>["data"] => {
	const _trailerTypes = useQuery({
		queryKey: ["jobApi.trailerType.trailerTypeList"],
		queryFn: () =>
			jobApi.trailerType.trailerTypeList({}).then((x) => x.data.data),
		initialData: [],
	});
	const trailerTypes = useMemo(
		() =>
			_trailerTypes.data.map((x): TrailerType => {
				return {
					id: x.id,
					name: x.name,
				};
			}),
		[_trailerTypes.data],
	);
	return {
		data: trailerTypes,
		retry: _trailerTypes.refetch,
		loading: _trailerTypes.isFetching,
	};
};
export const TrailerTypesPage2 = () => {
	const data = useFetchData();
	const handleDelete = (id: number) =>
		toasted(
			jobApi.trailerType.trailerTypeDelete(id).then(data.retry),
			"Deleting Trailer Type",
		);
	const getForm = (
		id: number | undefined,
		onSubmit: (data: TrailerTypeFormObject) => void,
	) => {
		let defaultValues: Partial<TrailerTypeFormObject> = { name: "" };
		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};
		return (
			<TrailerTypeFormWithDTO
				onSubmit={onSubmit}
				defaultValues={defaultValues}
			/>
		);
	};
	return (
		<GenericPage
			pageTitle="Trailer Types"
			name="Trailer Type"
			data={data}
			onDelete={handleDelete}
			defaultColumns={defaultColumns}
			getForm={getForm}
		/>
	);
};
