import { Reveal } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import {
	ExpansionPanel,
	ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import type { Leg } from "./LegForm";
import { jobApi } from "./helpers";
import { useDialog } from "./helpersReact";

type ClosestDriverRecommendationsProps = {
	locationId: number | undefined;
	onRecommendationSelected?: (recommendation: Recommendation) => void;
};
const ClosestDriverRecommendations = ({
	locationId,
	onRecommendationSelected,
}: ClosestDriverRecommendationsProps) => {
	const [expanded, setExpanded] = useState(false);
	const data = useQuery({
		queryKey: ["jobApi.bff.bffClosestDriversRecommendationCreate", locationId],
		queryFn: () => {
			if (!locationId) {
				return [];
			}
			return jobApi.bff
				.bffClosestDriversRecommendationCreate({ locationId })
				.then((x) => x.data);
		},
		initialData: [],
	});
	return (
		<ExpansionPanel
			expanded={expanded}
			title={
				<>
					Closest Drivers
					{data.isFetchedAfterMount || (
						<Loader size="small" type="infinite-spinner" themeColor="success" />
					)}
				</>
			}
			onAction={() => setExpanded((x) => !x)}
			style={{ minWidth: 600 }}
		>
			<Reveal>
				{expanded && data.isFetchedAfterMount && (
					<ExpansionPanelContent>
						{data.data.map((x) => (
							<div key={x.driverId}>
								<pre>{JSON.stringify(x, null, 2)}</pre>
								<Button onClick={() => onRecommendationSelected?.(x)}>
									Select
								</Button>
							</div>
						))}
					</ExpansionPanelContent>
				)}
			</Reveal>
		</ExpansionPanel>
	);
};

type RecommendationsProps = {
	settings: string[];
	formData: Leg | undefined;
	onRecommendationSelected?: (recommendation: Recommendation) => void;
};
const Recommendations = ({
	settings,
	formData,
	onRecommendationSelected,
}: RecommendationsProps) => {
	const locationId =
		formData?.deliveryLocationId ??
		formData?.collectionLocationId ??
		formData?.storageLocation ??
		undefined;
	return (
		<div>
			{settings.includes("ClosestDrivers") && (
				<ClosestDriverRecommendations
					locationId={locationId}
					onRecommendationSelected={onRecommendationSelected}
				/>
			)}
		</div>
	);
};

type Recommendation = {
	driverId?: number | null;
	truckId?: number | null;
	trailerId?: number | null;
	subcontractorId?: number | null;
};

export const useRecommendationsDialog = (
	formData: Leg | undefined,
	onRecommendationSelected?: (recommendation: Recommendation) => void,
) => {
	const data = useQuery({
		queryKey: ["jobApi.bff.bffRecommendationSettingsList"],
		queryFn: () =>
			jobApi.bff.bffRecommendationSettingsList().then((x) => x.data),
		initialData: [],
	});
	const isEnabled = data.data.length > 0;
	const [toggleDialog, dialog] = useDialog(
		isEnabled && (
			<Recommendations
				settings={data.data}
				formData={formData}
				onRecommendationSelected={onRecommendationSelected}
			/>
		),
		"Recommendations",
	);
	return [toggleDialog, dialog, isEnabled] as const;
};
