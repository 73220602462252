import dayjs from "dayjs";
import { DFlex } from "./GenericFlex";

type DisplayLocationProps = {
	name?: string | null;
	address?: string | null;
	date?: Date | string | null;
};
export const DetailsDisplayLocation = ({
	name,
	address,
	date,
}: DisplayLocationProps) => (
	<DFlex column noGap>
		<div>{name ?? "-"}</div>
		<div>{address ?? "-"}</div>
		<div>{date ? dayjs(date).format("HH:mm:ss, DD/MM/YYYY") : "-"}</div>
	</DFlex>
);
