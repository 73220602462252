import { useMemo } from "react";
import { GenericDataGrid } from "./GenericDataGrid";
import {
	type GenericGridProps,
	NewJobStatus,
	type TypedGridColumnProps,
} from "./helpers";
import { JobStatusCell, toCell } from "./helpersReact";

export type InvoicingAction =
	| "allowViewDetails"
	| "allowEdit"
	| "allowPreviewInvoice"
	| "allowReInvoice"
	| "allowMarkAsSent"
	| "allowSendToInvoicingCheck"
	| "allowSendToOperations"
	| "allowAudit";

export type InvoicingActions = { [key in InvoicingAction]: boolean | null };

type Invoicing = {
	actions: InvoicingActions;
	id: number;
	uniqueId: string;
	vatRate: number | undefined;
	vatRateString: string;
	assignedToName: string;
	cost: number | undefined;
	costString: string;
	price: number | undefined;
	priceString: string;
	customerId: number;
	customerName: string;
	customerCurrencyCode: string | undefined;
	customerInvoiceType: number;
	customerInvoiceTypeString: string;
	grossSum: number | undefined;
	grossSumString: string;
	invoiceNumber: string;
	purchaseOrderNumber: string;
	status: number;
	statusString: string;
	totalLegsWithPods: number;
	totalLegsNeedingPods: number;
	ratioPodsString: string;
	startDate: Date;
	startDateString: string;
	endDate: Date;
	endDateString: string;
	invoiceDate: Date | undefined;
	invoiceDateString: string;
	latestJobHistoryEventValue: string;
};

const defaultColumns: TypedGridColumnProps<Invoicing>[] = [
	{ field: "uniqueId", title: "Unique ID" },
	{
		field: "statusString",
		title: "Status",
		cell: ({ dataItem }) => (
			<JobStatusCell
				status={dataItem.status}
				name={dataItem.statusString}
				title={
					dataItem.status === NewJobStatus.INVOICE_FAILED
						? dataItem.latestJobHistoryEventValue
						: undefined
				}
			/>
		),
	},
	{ field: "customerName", title: "Customer" },
	{ field: "customerInvoiceTypeString", title: "Invoice Type" },
	{ field: "invoiceNumber", title: "Invoice Number" },
	{
		field: "invoiceDate",
		title: "Invoice Date",
		cell: ({ dataItem }) => toCell(dataItem.invoiceDateString),
	},
	{
		field: "startDate",
		title: "Start Date",
		cell: ({ dataItem }) => toCell(dataItem.startDateString),
	},
	{
		field: "endDate",
		title: "End Date",
		cell: ({ dataItem }) => toCell(dataItem.endDateString),
	},
	{ field: "ratioPodsString", title: "PODs" },
	{
		field: "vatRate",
		title: "VAT Rate",
		cell: ({ dataItem }) => toCell(dataItem.vatRateString),
	},
	{
		field: "cost",
		title: "Cost",
		cell: ({ dataItem }) => toCell(dataItem.costString),
	},
	{
		field: "customerCurrencyCode",
		title: "Currency",
		hidden: true,
	},
	{
		field: "grossSum",
		title: "Gross Sum",
		cell: ({ dataItem }) => toCell(dataItem.grossSumString),
	},
	{
		field: "price",
		title: "Price",
		cell: ({ dataItem }) => toCell(dataItem.priceString),
	},
	{ field: "purchaseOrderNumber", title: "Purchase Order Number" },
	{ field: "assignedToName", title: "Assigned To" },
];
export const InvoicingGrid = ({
	extraColumns = [],
	...rest
}: GenericGridProps<Invoicing>) => {
	const columns = useMemo(
		() => [...defaultColumns, ...extraColumns],
		[extraColumns],
	);
	return (
		<GenericDataGrid defaultColumns={columns} name="Invoicing" {...rest} />
	);
};
