import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { DFlex } from "./GenericFlex";
import {
	GenericForm,
	IDateTime,
	IDropzone,
	ISelect,
	ITextArea,
	type LoadOptionsFn,
} from "./GenericForm";
import { type LoadFormObject, LoadSchema } from "./Load";

type LoadFormProps = {
	defaultValues?: Partial<LoadFormObject>;
	onSubmit: (data: LoadFormObject) => void;
	lLoadTypes: LoadOptionsFn;
	lUsers: LoadOptionsFn;
	lLocations: LoadOptionsFn;
	lTrailers: LoadOptionsFn;
};

export const LoadForm = ({
	defaultValues,
	onSubmit,
	lLoadTypes,
	lLocations,
	lUsers,
	lTrailers,
}: LoadFormProps) => {
	const form = useForm<LoadFormObject>({
		resolver: yupResolver(LoadSchema),
		defaultValues,
	});
	return (
		<GenericForm
			form={form}
			schema={LoadSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<ISelect n="loadTypeId" l={lLoadTypes} />
					<ISelect n="startLocationId" l={lLocations} />
					<IDateTime n="startDate" />
					<ISelect n="endLocationId" l={lLocations} />
					<IDateTime n="endDate" />
				</div>
				<div>
					<ISelect n="trailerId" l={lTrailers} />
					<ISelect n="assignedTo" l={lUsers} />
					<ITextArea n="notes" />
					<IDropzone n="documents" />
				</div>
			</DFlex>
		</GenericForm>
	);
};
