import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import { useParams } from "react-router-dom";
import { DFlex } from "./GenericFlex";
import { useLegFormDialog } from "./useLegFormDialog";

export const LegFormPage = () => {
	const params = useParams(); // http://localhost:4200/legs/edit/75421
	const id = Number(params.id); // 75421
	const jobId = 22364;
	const [openDialog, modal] = useLegFormDialog();
	return (
		<Card>
			<CardHeader>
				<h2>Leg Form</h2>
			</CardHeader>
			<CardBody>
				{modal}
				<DFlex column>
					<button type="button" onClick={() => openDialog({ id })}>
						Open Modal to edit leg {id}
					</button>
					<button type="button" onClick={() => openDialog({ jobId })}>
						Open Modal to add leg to job {jobId}
					</button>
				</DFlex>
			</CardBody>
		</Card>
	);
};
